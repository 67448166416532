
import {
  defineComponent, ref
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import DlcRegistrationWrapper from '@/components/pages/services/numbers/dlcRegistration/DlcRegistrationWrapper.vue'
import { countryCodes } from '@/definitions/_general/_data/optionsList'
import industryVariant from '@/definitions/_general/_data/industryVariant'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    DlcRegistrationWrapper,
  },
  setup() {
    const companyName = ref('')
    const brandName = ref('')

    const countryRegistrationVariant = ref(countryCodes.map((c) => c.label))
    const countryRegistration = ref('United States')

    const legalFormVariant = ref([
      'Private profit',
      'Public profit',
      'Non profit',
      'Government',
    ])
    const legalForm = ref('Private profit')

    const taxNumber = ref('')
    const taxNumberCountryVariant = ref(countryCodes.map((c) => c.label))
    const taxNumberCountry = ref('United States')

    const address = ref('')
    const city = ref('')

    const state = ref('')
    const postalCode = ref('')

    const website = ref('')

    const email = ref('')
    const phone = ref('')

    const industrySelectVariant = ref(industryVariant)
    const industry = ref(industryVariant[0])

    return {
      companyName,
      brandName,
      countryRegistrationVariant,
      countryRegistration,
      legalFormVariant,
      legalForm,
      taxNumber,
      taxNumberCountryVariant,
      taxNumberCountry,
      address,
      city,
      state,
      postalCode,
      website,
      email,
      phone,
      industrySelectVariant,
      industry,
    }
  },
})
